import pimg1 from '../images/portfolio/1.jpg'
import pimg2 from '../images/portfolio/2.jpg'
import pimg3 from '../images/portfolio/3.jpg'
import pimg4 from '../images/portfolio/4.jpg'

import ps1img1 from '../images/project-single/img-1.jpg'
import ps1img2 from '../images/project-single/img-2.jpg'
import ps1img3 from '../images/project-single/img-3.jpg'
import ps1img4 from '../images/project-single/img-4.jpg'

import psub1img1 from '../images/project-single/2.jpg'
import psub1img2 from '../images/project-single/3.jpg'


const Projects = [
    {
       Id:'1',
       pImg:pimg1,
       ps1img:ps1img1,
       psub1img1:psub1img1,
       psub1img2:psub1img2,
       title:'ui/ux design',  
       subTitle:'Website / mobile apps',   
    },
    {
       Id:'2',
       pImg:pimg2,
       ps1img:ps1img2,
       psub1img1:psub1img1,
       psub1img2:psub1img2,
       title:'Web Development', 
       subTitle:'Website / mobile apps',    
    },
    {
      Id:'3',
      pImg:pimg3,
      ps1img:ps1img3,
      psub1img1:psub1img1,
      psub1img2:psub1img2,
      title:'Graphic Design',  
      subTitle:'Website / mobile apps',   
   },
   {
      Id:'4',
      pImg:pimg4,
      ps1img:ps1img4,
      psub1img1:psub1img1,
      psub1img2:psub1img2,
      title:'Brand Identity', 
      subTitle:'Website / mobile apps',    
   }
    
]

export default Projects;