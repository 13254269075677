import React from 'react'
import fImg from '../../images/fun-fact-shape.png'

const FunFact = (props) => {
    return (
        <section className="wpo-fun-fact-section">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="wpo-fun-fact-grids clearfix">
                            <div className="grid">
                                <div className="info">
                                    <h3 className="ff-color-1">12</h3>
                                    <p>years of experiecne</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="info">
                                    <h3 className="ff-color-2">100%</h3>
                                    <p>customer
                                        satisfaction</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="info">
                                    <h3 className="ff-color-3">72+</h3>
                                    <p>clients on
                                        world wide</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="info">
                                    <h3 className="ff-color-4">200+</h3>
                                    <p>complete
                                        customer projects</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fun-fact-shape">
                <img src={fImg} alt=""/>
            </div>
        </section>
    )
}

export default FunFact;