import sGimgS1 from '../images/service/logo-1.svg'
import sGimgS2 from '../images/service-single/graphics/img-1.jpg'
import sGimgS3 from '../images/service-single/graphics/img-2.jpg'
import sGimgS4 from '../images/service-single/graphics/img-3.jpg'

import sUxImgS1 from '../images/service/logo-2.svg'
import sUxImgS2 from '../images/service-single/ux/img-1.jpg'
import sUxImgS3 from '../images/service-single/ux/img-2.jpg'
import sUxImgS4 from '../images/service-single/ux/img-3.jpg'

import sDvImgS1 from '../images/service/logo-3.svg'
import sDvImgS2 from '../images/service-single/development/img-1.jpg'
import sDvImgS3 from '../images/service-single/development/img-2.jpg'
import sDvImgS4 from '../images/service-single/development/img-3.jpg'



const Services = [
    {
        Id: '1',
        sImg:sGimgS1,
        sTitle: 'Graphic Design',
        description:'It was popularised in the with the release of letraset sheets containing passages and more recently with software like versions.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-vector',
        ssImg:sGimgS2,
        ssImg2:sGimgS3,
        ssImg3:sGimgS4,
    },
    {
        Id: '2',
        sImg:sUxImgS1,
        sTitle: 'UI/UX DESIGN',
        description:'It was popularised in the with the release of letraset sheets containing passages and more recently with software like versions.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-palette',
        ssImg:sUxImgS2,
        ssImg2:sUxImgS3,
        ssImg3:sUxImgS4,
    },
    {
        Id: '3',
        sImg:sDvImgS1,
        sTitle: 'web development',
        description:'It was popularised in the with the release of letraset sheets containing passages and more recently with software like versions.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-add',
        ssImg:sDvImgS2,
        ssImg2:sDvImgS3,
        ssImg3:sDvImgS4,
    }
]    

export default Services;