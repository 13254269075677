import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/hero/hero';
import FunFact from '../../components/FunFact/FunFact';
import About from '../../components/about/about';
import BlogSection from '../../components/BlogSection/BlogSection';
import ContactArea from '../../components/ContactArea';
import Pricing from '../../components/Pricing/Pricing';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';

const HomePage =() => {
    return(
        <Fragment>
            <Navbar/>
            <Hero/>
            <FunFact/>
            <ServiceSection/>
            <About/>
            <ProjectSection/>
            <Pricing/>
            <Testimonial/>
            <ContactArea/>
            <PartnerSection/>
            <BlogSection/> 
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage;