import React, { useState } from 'react';
import shape1 from '../../images/slider/shape-4.png'
import shape2 from '../../images/about-shape-1.png'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

const About = (props) => {
    
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    return (
        <section className="wpo-about-section" id='about'>
            <div className="shape-1">
                <img src={shape1} alt="" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-12">
                        <div className="about-left">
                            <div className="content">
                                <h3>about me</h3>
                                <h2>ui/ux designer
                                    <span className="span-1">with</span><span className="span-2"> 12+</span> years of
                                    experience.</h2>
                                <a href="about.html" className="theme-btn-s2">MY PORTFOLIO</a>
                                <div className="shape">
                                    <img src={shape2} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-12">

                        <div className="about-wrap">
                            <div className="about-tab">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={`${classnames({ active: activeTab === '1' })}`}
                                            onClick={() => { toggle('1'); }}
                                        >
                                            Design
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={`${classnames({ active: activeTab === '2' })}`}
                                            onClick={() => { toggle('2'); }}
                                        >
                                            Development
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={`${classnames({ active: activeTab === '3' })}`}
                                            onClick={() => { toggle('3'); }}
                                        >
                                            Marketing
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <div className="row align-items-center">
                                        <div className="about-single-item">
                                            <div className="content">
                                                <h3>my advanctage</h3>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                                    has been the industry's standard dummy text ever since the 1500s,
                                                    when an unknown printer took galley type and scrambled it to make a type
                                                    specimen book.</p>
                                            </div>
                                            <div className="skills-wrap">
                                                <div className="progress-item">
                                                    <div className="progress-text">
                                                        <span>adobe photoshop</span>
                                                    </div>
                                                    <div className="donate-progress">
                                                        <div className="cssProgress-bar" style={{ width: `${80}%` }}>
                                                        </div>
                                                    </div>
                                                    <span className="cssProgress-label">80%</span>
                                                </div>
                                                <div className="progress-item progress-item-1">
                                                    <div className="progress-text">
                                                        <span>adobe illustrator</span>
                                                    </div>
                                                    <div className="donate-progress">
                                                        <div className="cssProgress-bar" style={{ width: `${78}%` }}>
                                                        </div>
                                                    </div>
                                                    <span className="cssProgress-label">78%</span>
                                                </div>
                                                <div className="progress-item progress-item-2">
                                                    <div className="progress-text">
                                                        <span>adobe xd</span>
                                                    </div>
                                                    <div className="donate-progress">
                                                        <div className="cssProgress-bar" style={{ width: `${80}%` }}>
                                                        </div>
                                                    </div>
                                                    <span className="cssProgress-label">80%</span>
                                                </div>
                                                <div className="progress-item progress-item-3">
                                                    <div className="progress-text">
                                                        <span>figma</span>
                                                    </div>
                                                    <div className="donate-progress">
                                                        <div className="cssProgress-bar" style={{ width: `${90}%` }}>
                                                        </div>
                                                    </div>
                                                    <span className="cssProgress-label">90%</span>
                                                </div>
                                                <div className="progress-item progress-item-4">
                                                    <div className="progress-text">
                                                        <span>html/css</span>
                                                    </div>
                                                    <div className="donate-progress">
                                                        <div className="cssProgress-bar" style={{ width: `${60}%` }}>
                                                        </div>
                                                    </div>
                                                    <span className="cssProgress-label">60%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="row align-items-center">
                                        <div className="about-single-item">
                                            <div className="content">
                                                <h3>my skills</h3>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                                    has been the industry's standard dummy text ever since the 1500s,
                                                    when an unknown printer took galley type and scrambled it to make a type
                                                    specimen book.</p>
                                            </div>
                                            <div className="skills-wrap">
                                                <div className="progress-item">
                                                    <div className="progress-text">
                                                        <span>adobe photoshop</span>
                                                    </div>
                                                    <div className="donate-progress">
                                                        <div className="cssProgress-bar" style={{ width: `${95}%` }}>
                                                        </div>
                                                    </div>
                                                    <span className="cssProgress-label">95%</span>
                                                </div>
                                                <div className="progress-item progress-item-1">
                                                    <div className="progress-text">
                                                        <span>adobe illustrator</span>
                                                    </div>
                                                    <div className="donate-progress">
                                                        <div className="cssProgress-bar" style={{ width: `${80}%` }}>
                                                        </div>
                                                    </div>
                                                    <span className="cssProgress-label">80%</span>
                                                </div>
                                                <div className="progress-item progress-item-2">
                                                    <div className="progress-text">
                                                        <span>adobe xd</span>
                                                    </div>
                                                    <div className="donate-progress">
                                                        <div className="cssProgress-bar" style={{ width: `${68}%` }}>
                                                        </div>
                                                    </div>
                                                    <span className="cssProgress-label">68%</span>
                                                </div>
                                                <div className="progress-item progress-item-3">
                                                    <div className="progress-text">
                                                        <span>figma</span>
                                                    </div>
                                                    <div className="donate-progress">
                                                        <div className="cssProgress-bar" style={{ width: `${79}%` }}>
                                                        </div>
                                                    </div>
                                                    <span className="cssProgress-label">89%</span>
                                                </div>
                                                <div className="progress-item progress-item-4">
                                                    <div className="progress-text">
                                                        <span>html/css</span>
                                                    </div>
                                                    <div className="donate-progress">
                                                        <div className="cssProgress-bar" style={{ width: `${65}%` }}>
                                                        </div>
                                                    </div>
                                                    <span className="cssProgress-label">65%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="3">
                                    <div className="row align-items-center">
                                        <div className="about-single-item">
                                            <div className="content">
                                                <h3>work experience</h3>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                                    has been the industry's standard dummy text ever since the 1500s,
                                                    when an unknown printer took galley type and scrambled it to make a type
                                                    specimen book.</p>
                                            </div>
                                            <div className="skills-wrap">
                                                <div className="progress-item">
                                                    <div className="progress-text">
                                                        <span>adobe photoshop</span>
                                                    </div>
                                                    <div className="donate-progress">
                                                        <div className="cssProgress-bar" style={{ width: `${98}%` }}>
                                                        </div>
                                                    </div>
                                                    <span className="cssProgress-label">98%</span>
                                                </div>
                                                <div className="progress-item progress-item-1">
                                                    <div className="progress-text">
                                                        <span>adobe illustrator</span>
                                                    </div>
                                                    <div className="donate-progress">
                                                        <div className="cssProgress-bar" style={{ width: `${77}%` }}>
                                                        </div>
                                                    </div>
                                                    <span className="cssProgress-label">77%</span>
                                                </div>
                                                <div className="progress-item progress-item-2">
                                                    <div className="progress-text">
                                                        <span>adobe xd</span>
                                                    </div>
                                                    <div className="donate-progress">
                                                        <div className="cssProgress-bar" style={{ width: `${72}%` }}>
                                                        </div>
                                                    </div>
                                                    <span className="cssProgress-label">72%</span>
                                                </div>
                                                <div className="progress-item progress-item-3">
                                                    <div className="progress-text">
                                                        <span>figma</span>
                                                    </div>
                                                    <div className="donate-progress">
                                                        <div className="cssProgress-bar" style={{ width: `${95}%` }}>
                                                        </div>
                                                    </div>
                                                    <span className="cssProgress-label">95%</span>
                                                </div>
                                                <div className="progress-item progress-item-4">
                                                    <div className="progress-text">
                                                        <span>html/css</span>
                                                    </div>
                                                    <div className="donate-progress">
                                                        <div className="cssProgress-bar" style={{ width: `${58}%` }}>
                                                        </div>
                                                    </div>
                                                    <span className="cssProgress-label">58%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
