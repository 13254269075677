import React, { useState } from 'react';
import Projects from '../../api/project'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import ProjectSingle from '../ProjectSingle/ProjectSingle';
import tshape1 from '../../images/portfolio/shape-1.png'
import tshape2 from '../../images/portfolio/shape-2.png'
import tshape3 from '../../images/portfolio/shape-3.png'
import tshape4 from '../../images/portfolio/shape-4.png'
import tshape5 from '../../images/portfolio/shape-5.png'




const ProjectSection = () => {

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state, setState] = useState({
    })

    const handleClickOpen = (item) => {
        setOpen(true);
        setState(item)
    }

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    return (
        <section className="wpo-portfolio-section" id='portfolio'>
            <div className="portfolio-wrap section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col col-md-6 col-12">
                            <div className="wpo-section-title">
                                <h2>some of my work</h2>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-content-wrap">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-12">
                                <div className="portfolio-content-left">
                                    <Nav tabs className="portfolio-slider-nav">
                                        {Projects.map((project, pro) => (
                                            <NavItem key={pro}>
                                                <NavLink
                                                    className={`${classnames({ active: activeTab === project.Id })}`}
                                                    onClick={() => { toggle(project.Id); }}
                                                >
                                                    {project.title}
                                                </NavLink>
                                            </NavItem>
                                        ))}
                                    </Nav>
                                </div>
                            </div>
                            <div className="col-lg-7 col-12">
                                <div className="portfolio-right-content">
                                    <div className="portfolio-slider-for">
                                        <TabContent activeTab={activeTab}>
                                            {Projects.map((project, pro) => (
                                                <TabPane tabId={project.Id} key={pro}>
                                                    <div className="image">
                                                        <img src={project.pImg} alt="" />
                                                        <div className="text">
                                                            <h2 onClick={() => handleClickOpen(project)}>{project.title}</h2>
                                                            <span>Website / mobile apps</span>
                                                        </div>
                                                    </div>
                                                </TabPane>
                                            ))}
                                        </TabContent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-1">
                <img src={tshape1} alt="" />
            </div>
            <div className="shape-2">
                <img src={tshape2} alt="" />
            </div>
            <div className="shape-3">
                <img src={tshape3} alt="" />
            </div>
            <div className="shape-4">
                <img src={tshape4} alt="" />
            </div>
            <div className="shape-5">
                <img src={tshape5} alt="" />
            </div>
            <ProjectSingle open={open} onClose={handleClose} title={state.title} pImg={state.ps1img} psub1img1={state.psub1img1} psub1img2={state.psub1img2} />
        </section>
    );
}

export default ProjectSection;