import React from 'react'
import { Link } from 'react-router-dom'


const Pricing = (props) => {

    return (
        <section className="wpo-pricing-section section-padding" id='pricing'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-lg-6 col-md-10 col-12">
                        <div className="wpo-section-title">
                            <h2>Choose Your Pricing Plan</h2>
                        </div>
                    </div>
                </div>
                <div className="pricing-grids clearfix">
                    <div className="grid">
                        <div className="type">
                            <h5>Basic</h5>
                        </div>
                        <div className="pricing-header">
                            <div>
                                <h3 className="price">$50</h3>
                                <p>Per Month</p>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul>
                                <li>Softwere Development</li>
                                <li>Web Development</li>
                                <li>Digital Marketing</li>
                                <li>Graphic Design</li>
                                <li>24/Support</li>
                            </ul>
                            <Link to="/" className="get-started">Get Started</Link>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="type">
                            <h5>ULTRA</h5>
                        </div>
                        <div className="pricing-header">
                            <div>
                                <h3 className="price">$68</h3>
                                <p>Per Month</p>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul>
                                <li>Softwere Development</li>
                                <li>Web App Development</li>
                                <li>Web Development</li>
                                <li>Digital Marketing</li>
                                <li>Graphic Design</li>
                                <li>24/Support</li>
                            </ul>
                            <Link to="/" className="get-started">Get Started</Link>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="type">
                            <h5>Pro</h5>
                        </div>
                        <div className="pricing-header">
                            <div>
                                <h3 className="price">$75</h3>
                                <p>Per Month</p>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul>
                                <li>Softwere Development</li>
                                <li>Web Development</li>
                                <li>Digital Marketing</li>
                                <li>Graphic Design</li>
                                <li>24/Support</li>
                            </ul>
                            <Link to="/" className="get-started">Get Started</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pricing;