import React from 'react'
import ContactForm from '../ContactFrom/ContactForm'


const ContactArea = (props) => {
    return (
        <section className="wpo-contact-section section-padding" id='contact'>
            <div className="container">
                <div className="wpo-contact-section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className="contact-info-wrap">
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="fi flaticon-location"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h4>Office Address</h4>
                                        <p>244 Royal Ln. Mesa, New Jersey </p>
                                    </div>
                                </div>
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="fi flaticon-mail"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h4>Email Address</h4>
                                        <p>portxdemo@gmail.com</p>
                                    </div>
                                </div>
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="fi flaticon-phone-call"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h4>Phone</h4>
                                        <p>(684) 555-0102</p>
                                    </div>
                                </div>
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="fi flaticon-user"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h4>Office Hour</h4>
                                        <p>Mon-Sun: 10am – 7pm</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-contact-form-area">
                                <ContactForm />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="wpo-contact-map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.9147703055!2d-74.11976314309273!3d40.69740344223377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York%2C+NY%2C+USA!5e0!3m2!1sen!2sbd!4v1547528325671"
                                    allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-1">
                <svg width="1329" height="938" viewBox="0 0 1329 938" fill="none">
                    <g opacity="0.23" filter="url(#filter0_f_1_400)">
                        <circle cx="447" cy="186" r="538" fill="url(#paint0_linear_1_4008)" />
                    </g>
                    <defs>
                        <filter id="filter0_f_1_400" x="-435" y="-696" width="1764" height="1764"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="172" result="effect1_foregroundBlur_1_400" />
                        </filter>
                        <linearGradient id="paint0_linear_1_4008" x1="447" y1="-352" x2="447" y2="724"
                            gradientUnits="userSpaceOnUse">
                            <stop offset="0" stopColor="#FA00FF" />
                            <stop offset="1" stopColor="#FFDA27" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        </section>
    )
}

export default ContactArea;